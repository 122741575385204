export default {
    siteTitle: 'Скала-подільський Спецкарʼєр',
    index: 'Головна',
    about: 'Про нас',
    products: 'Продукція',
    logistics: 'Логістика',
    reporting: 'Звітність',
    contacts: 'Контакти',
    requestPrice: 'Дізнатись ціну',
    logo: 'СПСК',
    copyright: '(©) 2019 ТОВ "Скала-подільський Спецкарʼєр"',
    railwayTransportation: 'Залізничні перевезення',
    autotransportations: 'Автоперевезення (автоцистернами)',
    pickUp: 'Самовивіз',
    otherProduct: 'ІНША ПРОДУКЦІЯ ТА ПОСЛУГИ(ТІЛЬКИ В МЕЖАХ 40 КМ)',
    image: 'Фото',
    writeToUs: 'Написати нам',
    name: "Ім'я",
    phoneNumber: 'Номер телефону',
    message: 'Повідомлення',
    submit: 'Відправити',
    ceo: 'Виконавчий директор',
    ceoName: 'Шашкіна Наталія Василівна',
    financialDepartment: 'Фінансовий відділ',
    financialDepartmentName: 'Михайлов Микола Віталійович',
    supplyDepartment: 'Відділ постачання',
    supplyDepartmentName: 'Кулик Андрій Миколайович',
    salesDepartment: 'Відділ продажу',
    salesDepartmentName: 'Гриньків Тетяна Володимирівна',
    bottomTitle:
        'ТОВ "Скала-подільський Спецкарʼєр" поставляє надійну і якісну продукцію власного виробництва з власної сировини останні 70 років ',
    logisticsTopTitle:
        ' Доставка мінерального порошку в будь-яку точку України',
    productsTopTitle: 'Мінеральний порошок (ДСТУ Б В.2.7-121-2014)',
    quality: 'Якість',
    aboutTopTitle: 'Якість і надійність перевірена часом',
    qualityСertificate: 'Сертифікат якості',
    year: 'рік',
    ourHistory: 'Наша історія',
}
