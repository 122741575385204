import React from 'react'
import Img from 'gatsby-image'
import makeCarousel from 'react-reveal/makeCarousel'
import Slide from 'react-reveal/Slide'
import ShewronLeft from 'assets/icons/chevronleft.svg'
import ShewronRight from 'assets/icons/chevronright.svg'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    overflow: hidden;
    height: 100vh;
    width: 133vh;
    @media (orientation: portrait) {
        width: 100vw;
        height: 75vw;
    }
`
const ArrowL = styled.div`
    display: flex;
    z-index: 2;
    width: 10%;
    height: 100%;
    & :hover {
        background-color: #000;
        opacity: 0.5;
        transition-duration: 1s;
    }
    & svg {
        width: 40%;
        justify-content: center;
        padding-left: 20%;
        :hover {
            fill: white;
            opacity: 1;
            transition-duration: 1s;
        }
    }
`

const ArrowR = styled(ArrowL)`
    & svg {
        padding-left: 40%;
    }
`
const SizeElements = {
    height: '100%',
    width: '100%',
}
const Slider = ({ images }) => {
    const CarouselUI = ({ position, handleClick, children }) => (
        <Container>
            {children}
            <ArrowL
                aria-label="Попереднє фото"
                onClick={handleClick}
                data-position={position - 1}
            >
                <ShewronLeft />
            </ArrowL>
            <ArrowR
                aria-label="Наступне фото"
                onClick={handleClick}
                data-position={position + 1}
            >
                <ShewronRight />
            </ArrowR>
        </Container>
    )
    const Carousel = makeCarousel(CarouselUI)
    return (
        <Carousel style={SizeElements} defaultWait={0}>
            {images.map(({ node }) => (
                <Slide style={SizeElements}>
                    <Img
                        key={node.id}
                        alt="Фото"
                        style={SizeElements}
                        fluid={node.childImageSharp.fluid}
                    />
                </Slide>
            ))}
        </Carousel>
    )
}

export default Slider
