import { useLocale } from 'i18n/useLocale'
import { get } from 'lodash'

// Only one item MUST have the "default: true" key
export const localeConfigs = {
    uk: {
        default: true,
        locale: `uk`,
        path: `uk`,
        languageTag: `uk-UA`,
        dateFormat: `DD.MM.YYYY`,
        ogLanguage: `uk_UA`,
        defaultTitle: `Скала-подільський Спецкарʼєр`,
        defaultDescription: `СПСК - Скала-подільський Спецкарʼєр`,
    },
    en: {
        locale: `en`,
        path: `en`,
        languageTag: `en-US`,
        dateFormat: `DD/MM/YYYY`,
        ogLanguage: `en_US`,
        defaultTitle: `Skala-подільський Спецкарʼєр`,
        defaultDescription: `SPSK - Скала-подільський Спецкарʼєр`,
    },
}

export const isDefaultLocale = (locale) =>
    get(localeConfigs, `${locale}.default`, false)

export const useLocaleConfig = () => {
    const locale = useLocale()

    return localeConfigs[locale] || locale.uk
}
